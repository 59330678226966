<template>
	<ul
		:class="[
			{ 'border-t-2 border-color-grey-lightest': monochrome },
			{ 'space-y-3': minify },
			{ 'divide-y divide-color-grey-lightest': leanList }
		]"
	>
		<template v-if="relations">
			<div
				v-for="(relation, index) in relations"
				:key="relation.obj._id"
				:class="
					(uspCards &&
						index === 3 &&
						(currentPage === 1 || currentPage === 3 || currentPage === 5) &&
						region === 'de') ||
					minify ||
					leanList
						? 'border-0'
						: 'border-b-2 border-color-grey-lightest'
				"
			>
				<!--	USP Cards-->
				<div
					v-if="
						uspCards && index === 4 && (currentPage === 1 || currentPage === 3 || currentPage === 5)
					"
					class="flex justify-center bg-color-grey-background p-4 w-full"
				>
					<div class="shadow-xl rounded-xl bg-color-white pt-4 pb-7 px-4 w-[358px] h-auto">
						<p
							class="font-bold text-center text-2xl bg-gradient-to-br from-color-main to-color-main-business bg-clip-text text-transparent mb-4"
							v-html="uspCards[iterateAds(currentPage)].headline"
						/>
						<p class="flex items-center mb-2 space-x-3 px-2 text-color-text">
							<HokIcon name="icon:checkmark-gradient" /><span class="text-sm">
								{{ uspCards[iterateAds(currentPage)].firstLine }}</span
							>
						</p>
						<p class="flex items-center mb-0 space-x-3 px-2 text-color-text">
							<HokIcon name="icon:checkmark-gradient" /><span class="text-sm"
								>{{ uspCards[iterateAds(currentPage)].secondLine }}
							</span>
						</p>
					</div>
				</div>

				<!--	Satisfactory tracking-->

				<transition name="shrink" mode="out-in">
					<div
						v-if="
							index === 8 &&
							satisfactoryTracking &&
							!satisfactorySlotClosed &&
							(currentPage === 1 || currentPage === 3)
						"
						class="text-center py-4 border-b-2 border-color-grey-lightest"
					>
						<div class="flex relative justify-center items-center mb-2">
							<p class="mb-0 font-bold" :class="!$isMobile.any ? 'text-sm' : 'text-xs'">
								Wie relevant sind die Suchergebnisse für dich?
							</p>
							<HokIcon
								name="icon:close-slim"
								:size="!$isMobile.any ? 5 : 4"
								class="absolute right-3 cursor-pointer"
								@click="satisfactorySlotClosed = true"
							/>
						</div>

						<div class="flex justify-center">
							<HokIcon
								v-for="(smiley, i) in smileys"
								:key="i"
								:size="7"
								:name="smiley"
								color="grey-medium"
								class="cursor-pointer hover:scale-110 mb-1"
								:class="$isMobile.any ? 'mx-4' : 'mx-5'"
								@click="clickSatisfactoryLevel(i)"
							/>
						</div>
					</div>
				</transition>

				<div v-if="relation.type === 'job'" @click="rowItemClicked(relation.obj, $event)">
					<JobListItem
						:is-user-app="isUserApp"
						:tag-name="tagArray && tagArray[index]"
						:job="relation.obj"
						:enable-debug="enableDebug"
						:debug="debugJob(index)"
						:job-is-saved="getStatus(relation.obj)"
						:similar-job-list="similarJobList"
						:minify="minify"
						:styling="styling"
						:lean-list="leanList"
						:attributes="(relation && relation.attributes) || {}"
						:class="[
							{ 'bg-color-main-light': activeJob === relation.obj.jobNr },
							{ 'border-l-4 border-transparent': monochrome },
							{
								'first:rounded-t-lg last:rounded-b-lg': !monochrome && !minify
							},
							{ 'bg-color-blue-light rounded-lg': minify },
							{
								'hover:bg-color-main-light': styling !== 'offline'
							}
						]"
						v-bind="$attrs"
						@save-job="$emit('save-job', relation.obj)"
						@prevent-job-redirect="preventJobRedirect = true"
					/>
				</div>
				<JobListCompanySlot
					v-if="relation.type === 'company'"
					:company="relation.obj"
					class="w-full"
					@company-clicked="$emit('company-clicked', relation.obj)"
				/>
			</div>
		</template>
		<template v-else>
			<div
				v-for="(job, index) in jobs"
				:key="job.job._id"
				:class="{ 'border-b-2 border-color-grey-lightest': !minify && !leanList }"
			>
				<div @click="rowItemClicked(job.job, $event)">
					<JobListItem
						:is-user-app="isUserApp"
						:attributes="job.attributes ? job.attributes : {}"
						:job="job.job"
						:enable-debug="enableDebug"
						:debug="debugJob(index)"
						:job-is-saved="getStatus(job.job)"
						:similar-job-list="similarJobList"
						:minify="minify"
						:styling="styling"
						:lean-list="leanList"
						:class="{
							'hover:bg-color-main-light': styling !== 'offline'
						}"
						v-bind="$attrs"
						@save-job="$emit('save-job', job.job)"
						@prevent-job-redirect="preventJobRedirect = true"
					/>
				</div>
			</div>
		</template>
	</ul>
</template>

<script lang="ts">
import type {
	IAPIJobPreviewForUser,
	IAPIMatchForJobSeekerListEntry,
	IAPICompanySnippet,
	LikeUser
} from '@hokify/common';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { lsTest } from '../helpers/localstorage';
import JobListItem from './JobListItem.vue';
import JobListCompanySlot from './JobListCompanySlot.vue';

export interface IJobPreviewItem {
	job: IAPIJobPreviewForUser;
	attributes?: {
		cityCanonical?: string;
		filters?: any[];
		requiredFields?: any[];
	};
}

export default defineComponent({
	name: 'JobList',
	components: {
		JobListItem,
		JobListCompanySlot
	},
	emits: ['row-item-clicked', 'jobsearch-feedback-clicked', 'save-job', 'company-clicked'],
	data() {
		const isLoadingSaved: boolean | 'error' = false;
		const preventJobRedirect = false;
		const tagArray = undefined as undefined | string[];
		const tagState: { [key: string]: { occurrence: number; lastIndex: number } } = {};

		return {
			tagState,
			tagArray,
			isLoadingSaved,
			preventJobRedirect,
			relation: undefined,
			satisfactorySlotClosed: false,
			smileys: [
				'icon:smiley-bad',
				'icon:smiley-sad',
				'icon:smiley-indifferent',
				'icon:smiley-smile',
				'icon:smiley-happy'
			]
		};
	},
	computed: {
		enableDebug() {
			return lsTest() && Boolean(localStorage.getItem('debug'));
		}
	},
	created() {
		this.computeTagArray();
		this.isLoadingSaved = true;
	},
	methods: {
		isTagAvailable(tag: string, i: number): boolean {
			let maxOccurence;
			let occurenceInterval;
			switch (tag) {
				case 'newjob':
				case 'popularjob':
					maxOccurence = 3;
					occurenceInterval = 3;
					break;
				case 'nodocument':
				case 'nocv':
					maxOccurence = 2;
					occurenceInterval = 2;
					break;
				case 'nomotivation':
					maxOccurence = 1;
					occurenceInterval = 1;
					break;
				// case 'finalcountdown':
				// case 'nodistancehome':
				// case 'nobirthday':
				// case 'nosalary':
				// 	maxOccurence = 0;
				// 	break;
				default:
					maxOccurence = 0;
					occurenceInterval = Number.POSITIVE_INFINITY;
			}
			return (
				this.tagState[tag]?.occurrence < maxOccurence &&
				Math.abs(this.tagState[tag] && this.tagState[tag].lastIndex - i) > occurenceInterval
			);
		},
		computeTagArray() {
			// reset tagState before each computation
			this.tagState = {};
			const arr: string[] = [];
			if (this.relations) {
				this.relations.forEach((job, index) => {
					if (job.type === 'job') arr.push(this.filterTags(job.obj?.tags, index));
				});
			}

			this.tagArray = arr;
		},
		filterTags(tags: string[], index: number): string {
			const blocklist = ['finalcountdown', 'nopic', 'nobirthday', 'nosalary', 'nodistancehome'];

			// get all tags which are still useable
			const potentialTags: { tag: string; occurrence: number }[] = [];
			tags.forEach(tag => {
				// limit by occurence
				if (!blocklist.includes(tag) && (!this.tagState[tag] || this.isTagAvailable(tag, index))) {
					potentialTags.push({ tag, occurrence: this.tagState[tag]?.occurrence || 0 });
				}
			});

			// sort by usage
			// potentialTags.sort((t1, t2) => t1.occurrence - t2.occurrence);

			// take top 1 and mark as used
			// OLD: const resultTags = potentialTags.slice(0, 1).map(t => t.tag);
			const resultTag = potentialTags[0]?.tag;
			if (!this.tagState[resultTag]) {
				this.tagState[resultTag] = { occurrence: 0, lastIndex: index };
			}
			this.tagState[resultTag].occurrence += 1;
			this.tagState[resultTag].lastIndex = index;

			return resultTag;
		},
		rowItemClicked(job: IAPIJobPreviewForUser, event): void {
			// We need to check whether user is trying to open link in a new tab/window on purpose.
			// Otherwise we make sure that we stay on page and open job in splitview.
			const newTabOrWindow: boolean =
				event.ctrlKey || event.shiftKey || event.metaKey || event.button === 1;
			if (!this.isUserApp && !newTabOrWindow && !this.preventJobRedirect) {
				event?.preventDefault();
				this.$router.push(`/job/${job.jobNr}`);
			}

			if (this.styling !== 'offline') {
				this.$emit('row-item-clicked', job);
			}

			if (this.preventJobRedirect) {
				// always reset to prevent any unwanted behaviour
				this.preventJobRedirect = false;
			}
		},
		getStatus(job: IAPIJobPreviewForUser): boolean {
			const stat =
				this.savedMatches?.some(match => {
					if (match.relation?.obj?._id === job._id) {
						return match.likeuser === LikeUser.Saved;
					}
					return false;
				}) || false;
			return stat;
		},
		debugJob(index: number) {
			return this.jobListDebug?.[index] || {};
		},
		jobsChanged() {
			this.computeTagArray();
		},
		iterateAds(pageNumber: number) {
			if (this.fromRoute === 'startpage') {
				switch (pageNumber) {
					case 3: {
						return 2;
					}
					case 5: {
						return 0;
					}
					default:
						return 1;
				}
			} else if (this.fromRoute === 'gnc') {
				switch (pageNumber) {
					case 3: {
						return 1;
					}
					case 5: {
						return 0;
					}
					default:
						return 2;
				}
			} else {
				switch (pageNumber) {
					case 3: {
						return 1;
					}
					case 5: {
						return 2;
					}
					default:
						return 0;
				}
			}
		},
		clickSatisfactoryLevel(index: number) {
			this.satisfactorySlotClosed = true;
			this.$emit('jobsearch-feedback-clicked', index);
		}
	},
	props: {
		relations: {
			type: Array as PropType<
				| { type: 'company'; obj: IAPICompanySnippet }[]
				| {
						type: 'job';
						obj: IAPIJobPreviewForUser;
						attributes?: {
							cityCanonical?: string;
							filters?: any[];
							requiredFields?: any[];
						};
				  }[]
			>,
			required: false
		},
		jobs: { type: Array as PropType<IJobPreviewItem[]>, required: false },
		jobListDebug: { type: Array },
		activeJob: { type: Number, default: null },
		monochrome: { type: Boolean, default: false },
		minify: { type: Boolean, default: false },
		leanList: { type: Boolean, default: false },
		styling: { type: String, default: 'live' },
		similarJobList: { type: Boolean, default: false },
		currentPage: { type: Number, default: null },
		savedMatches: { type: Array as PropType<IAPIMatchForJobSeekerListEntry[]>, required: false },
		uspCards: {
			type: Array as PropType<{ headline: string; firstLine: string; secondLine: string }[]>,
			required: false
		},
		region: { type: String, default: 'at' },
		fromRoute: { type: String, required: false },
		satisfactoryTracking: { type: Boolean, default: false },
		isUserApp: { type: Boolean, default: true }
	},
	watch: {
		jobs: [
			{
				handler: 'jobsChanged'
			}
		]
	}
});
</script>

<style scoped lang="scss">
.shrink-enter-active,
.shrink-leave-active {
	transition: all 0.2s ease-out;
	max-height: 450px;
}

.shrink-enter,
.shrink-leave-to {
	max-height: 0;
	opacity: 0;
}
</style>
